//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
export default {
	props: {
		file: {
			type: Object,
			default: function() {
				return {
					link: '',
					size: 0
				};
			}
		},
		duration: {
			type: Number,
			default: null
		}
	},
	computed: {
		getDuration() {
			if (this.duration) {
				let second = parseInt(this.duration % 60)<10&&this.duration / 60 >= 1 ?`0${parseInt(this.duration % 60)}''` :`${parseInt(this.duration %60)}''`;
				let minute = this.duration / 60 >= 1 ? `${parseInt(this.duration / 60)}'` : '';
				return minute + second;
			}
			return null;
		}
	},
	data() {
		return {
			volPic: null,
			time: null,
			audioURL: null,
			status: 'waiting',
			message: '加载中,请稍后'
		};
	},
	filters: {
		reName(filename) {
			if (filename) {
				return filename.replace(/ogg$|amr$|wave$/, 'mp3');
			}
			return filename;
		}
	},
	watch: {
		'file.link': {
			handler(newVal) {
				this.status = 'waiting';
				this.message = '加载中,请稍后';
			}
		}
	},
	mounted() {
		let audio = this.$refs.audio;
		let _this = this;
		const comm = err => {
			if (err.type === 'error') {
				console.log(audio.error);
			}
			clearInterval(_this.time);
			_this.volPic = null;
		};
		audio.addEventListener('error', comm, false);
		audio.addEventListener('ended', comm, false);

		audio.addEventListener(
			'canplay',
			function(e) {
				_this.status = 'success';
			},
			false
		);
		audio.addEventListener(
			'pause',
			function() {
				clearInterval(_this.time);
			},
			false
		);
	},
	methods: {
		...mapActions(['setAudio']),
		start(audio) {
			this.setAudio({
				obj: audio,
				index: this._uid
			});
			audio.play().catch(e => {
				// 音频加载失败
				console.log(e);
			});
			this.time = setInterval(() => {
				this.volPic += 1;
				if (this.volPic == 2) {
					this.volPic = -1;
				}
			}, 500);
		},
		playVoice() {
			let audio = this.$refs.audio;
			if (this.status === 'waiting') {
				this.audioURL = this.file.link;
				this.status = 'info';
				audio.load();
				this.start(audio);
			} else {
				if (this.status !== 'success') {
					this.$notify({
						type: this.status,
						message: this.message
					});
					return;
				}
				if (audio.paused) {
					this.start(audio);
				} else {
					audio.pause();
				}
			}
		}
	}
};
